import Button from '@/components/atoms/Button/Button'
import Checkbox from '@/components/atoms/Inputs/Checkbox/Checkbox'
import Text from '@/components/atoms/Inputs/Text'
import useOutsideAlerter from '@/components/atoms/OutsideAlerter/OutsideAlerter'
import {useOverlayContext} from '@/components/common/ProfileOverlayContext'
import {useWordPressContext} from '@/components/common/WordPressProvider'
import Form from '@/components/molecules/Form'
import {enableScroll} from '@/functions/enableDisableScroll'
import cn from 'classnames'
import {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import styles from './WaitingListForm.module.scss'

const WaitingListForm = () => {
  const {
    isWaitingListFormOpen,
    setIsWaitingListFormOpen,
    waitingListVariationId,
    setWaitingListVariationId
  } = useOverlayContext()
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const {post, themeOptions}: any = useWordPressContext()
  const {t} = useTranslation()
  const [isInitialRender, setIsIntialRender] = useState(false)
  const ref = useRef(null)

  useOutsideAlerter({
    ref,
    isOpen: isWaitingListFormOpen,
    setIsOpen: setIsWaitingListFormOpen,
    isOverlay: true
  })

  useEffect(() => {
    if (isWaitingListFormOpen) {
      enableScroll()
      setIsIntialRender(true)
    }
  }, [isWaitingListFormOpen])

  const handleCloseForm = () => {
    setIsWaitingListFormOpen(false)
    setWaitingListVariationId('')
    setIsSuccess(false)
  }

  const handleSubmit = async (values: {email: string}) => {
    const {email} = values
    setIsLoading(true)
    try {
      const response = await fetch('/api/waitlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          wc_bis_email: email,
          wc_bis_variation_id: waitingListVariationId,
          wc_bis_product_id: post.productId
        })
      })

      const result = await response.json()
      setIsLoading(false)
      if (!response.ok) {
        throw new Error(result.message || 'Failed to submit waitlist')
      }
      setIsSuccess(true)
    } catch (error) {
      console.error('Error calling waitlist API:', error)
    }
  }

  return (
    <div
      ref={ref}
      className={cn(styles.wrapper, isWaitingListFormOpen && [styles.formOpen])}
    >
      <Form
        id="waiting-list-form"
        title="Join the Waiting List"
        formDefaults={{
          email: ''
        }}
        className={
          !isInitialRender
            ? ''
            : isWaitingListFormOpen
            ? 'is--active'
            : 'is--inactive'
        }
        onClose={handleCloseForm}
        onSubmit={async (values) => {
          try {
            handleSubmit(values)
          } catch (error) {
            console.error('Error submitting the form:', error)
          }
        }}
      >
        {(formikProps) => (
          <div className={styles.waitingListFormWrapper}>
            <div className={styles.title}>
              {isSuccess
                ? t('programm:waitingListSuccessTitle')
                : t('programm:waitingListTitle')}
            </div>
            <div className={styles.subtitle}>
              {isSuccess
                ? t('programm:waitingListSuccessSubtitle')
                : t('programm:waitingListSubtitle')}
            </div>
            {isSuccess ? (
              <Button
                tag="button"
                text={t('account:closeLabel')}
                type="filled"
                icon={null}
                onClick={() => handleCloseForm()}
              />
            ) : (
              <>
                <div className={styles.fields}>
                  <Text
                    id="email"
                    label={`${t('checkout:emailLabel')} `}
                    placeholder={t('checkout:emailLabel')}
                    isRequired
                    type="EMAIL"
                    fullWidth={true}
                    setFieldValue={formikProps.setFieldValue}
                  />

                  <Checkbox
                    id="termsCheckbox"
                    label={themeOptions.consent}
                    isRequired
                    setFieldValue={formikProps.setFieldValue}
                  />
                </div>

                <div className={styles.bottomButtons}>
                  <Button
                    icon="buttonArrow"
                    iconSize={24}
                    tag="button"
                    text={t('programm:waitingListSubmitButton')}
                    type="filled"
                    isLoading={isLoading}
                    disabled={
                      !formikProps.values.email ||
                      !formikProps.values.termsCheckbox
                    }
                  />
                </div>
              </>
            )}
          </div>
        )}
      </Form>
    </div>
  )
}

export default WaitingListForm
