import cn from 'classnames'

import NavigationMenu from '../NavigationMenu/NavigationMenu'

import styles from './../Navigation/Navigation.module.scss'

import {MenuItem} from '@/components/types/types'
import SearchNavigation from '../SearchNavigation'

type Props = {
  menu: MenuItem[]
  activeSubMenuID: string
  menuOpen: boolean
}
export default function NavigationDesktop({
  menu,
  activeSubMenuID,
  menuOpen
}: Props) {
  return (
    <>
      {!!menu?.length && (
        <nav className={cn(styles.navigation, menuOpen && styles.menuOpen)}>
          <ul className={styles.menu}>
            {menuOpen && activeSubMenuID === 'search' ? (
              <SearchNavigation />
            ) : (
              menu.map((item, i) => {
                {
                  if (item.id === activeSubMenuID) {
                    return (
                      <NavigationMenu
                        menu={item.children}
                        active={true}
                        key={i}
                      />
                    )
                  }
                }
              })
            )}
          </ul>
        </nav>
      )}
    </>
  )
}
