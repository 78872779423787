import React, {Dispatch, SetStateAction, useContext, useState} from 'react'

interface OverlayContextProps {
  isFormOpen: boolean
  setIsFormOpen: Dispatch<SetStateAction<boolean>>
  isMoodleActivated: boolean
  setIsMoodleActivated: Dispatch<SetStateAction<boolean>>
  isWaitingListFormOpen: boolean
  setIsWaitingListFormOpen: Dispatch<SetStateAction<boolean>>
  waitingListVariationId: String
  setWaitingListVariationId: Dispatch<SetStateAction<String>>
}

export const OverlayContext = React.createContext<OverlayContextProps>({
  isFormOpen: false,
  setIsFormOpen: () => {},
  isMoodleActivated: false,
  setIsMoodleActivated: () => {},
  isWaitingListFormOpen: false,
  setIsWaitingListFormOpen: () => {},
  waitingListVariationId: '',
  setWaitingListVariationId: () => {}
})

export const OverlayProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children
}) => {
  const [isFormOpen, setIsFormOpen] = useState(false)
  const [isMoodleActivated, setIsMoodleActivated] = useState(false)
  const [isWaitingListFormOpen, setIsWaitingListFormOpen] = useState(false)
  const [waitingListVariationId, setWaitingListVariationId] =
    useState<String>('')

  return (
    <OverlayContext.Provider
      value={{
        isFormOpen,
        setIsFormOpen,
        isMoodleActivated,
        setIsMoodleActivated,
        isWaitingListFormOpen,
        setIsWaitingListFormOpen,
        waitingListVariationId,
        setWaitingListVariationId
      }}
    >
      {children}
    </OverlayContext.Provider>
  )
}

export const useOverlayContext = () => {
  const context = useContext(OverlayContext)
  return context
}
