import {searchClient} from '@/lib/algolia/connector'
import {useRouter} from 'next/router'
import {useState} from 'react'
import {
  Configure,
  connectStateResults,
  InstantSearch
} from 'react-instantsearch-dom'
import NavigationItem from '../NavigationItem'
import Search from '../Search/Search'
import styles from './SearchNavigation.module.scss'

export default function SearchNavigation() {
  const [searchValue, setSearchValue] = useState('')
  const hitsPerPage = 4
  const indexName = 'wp_searchable_posts'

  const config = {
    hitsPerPage: hitsPerPage
  }
  const {locale} = useRouter()

  const SearchResultsNavigation = connectStateResults(
    ({searchResults, searchState}: any) => {
      return (
        <div>
          {searchState?.query?.length > 0 && searchResults?.nbHits > 0 && (
            <div className={styles.hits}>
              {searchResults.hits.map((hit: any, index: number) => (
                <div key={index}>
                  <NavigationItem
                    key={hit.objectID}
                    item={{
                      id: hit.objectID,
                      path:
                        hit.taxonomies.language[0] === 'English'
                          ? hit.permalink.replace(
                              `${process.env.NEXT_PUBLIC_WEBSITE_URL}/`,
                              `${process.env.NEXT_PUBLIC_WEBSITE_URL}/en/`
                            )
                          : hit.permalink,
                      label: hit.post_title.replace('&amp;', '&'),
                      target: '',
                      children: []
                    }}
                    subMenuActive={false}
                    selected={false}
                    setActiveMenu={() => false}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      )
    }
  )

  return (
    <InstantSearch searchClient={searchClient} indexName={indexName}>
      <Configure
        {...config}
        filters={`post_type_label:"Products" AND taxonomies.language:"${
          locale === 'en' ? 'English' : 'Deutsch'
        }"`}
      />
      <div className={styles.searchWrapper}>
        <div className={styles.searchContainer}>
          <Search
            isAlgolioSearch
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        </div>
        {searchValue.length >= 3 && <SearchResultsNavigation />}
      </div>
    </InstantSearch>
  )
}
