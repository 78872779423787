import {wpApiUrlBase} from '@/lib/wordpress/connector'
import parse from 'html-react-parser'
import Head from 'next/head'
import {useRouter} from 'next/router'
import PropTypes from 'prop-types'

/**
 * Render extra meta tags in the document head.
 *
 * @author WebDevStudios
 * @param  {object}  props     The component attributes as props.
 * @param  {object}  props.seo Yoast SEO data from WordPress.
 * @return {Element}           The Meta component.
 */
export default function Meta({seo, post}) {
  const {locale, asPath} = useRouter()

  // Combine robots data.
  const robots = [
    ...(seo?.metaRobotsNofollow ? [seo.metaRobotsNofollow] : []),
    ...(seo?.metaRobotsNoindex ? [seo.metaRobotsNoindex] : [])
  ]
  const url = wpApiUrlBase.replace(/\/wp\/?/g, '')

  const regex = new RegExp(`${url}(?!/app)`, 'g')

  let seoFullHead = seo?.fullHead?.replace(
    regex,
    process.env.NEXT_PUBLIC_WEBSITE_URL
  )

  seoFullHead = seoFullHead?.replace('content="de_DE"', `content="${locale}"`)
  seoFullHead = seoFullHead?.replace('content="en_US"', `content="${locale}"`)

  const regexPattern = new RegExp(
    `${process.env.NEXT_PUBLIC_WEBSITE_URL}/wp`,
    'i'
  )

  seoFullHead = seoFullHead?.replace(
    regexPattern,
    process.env.NEXT_PUBLIC_WEBSITE_URL
  )

  seoFullHead = seoFullHead?.replaceAll('home-en/', 'en/')
  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta name="robots" content={robots.join(', ')} />
      <meta name="googlebot" content={robots.join(', ')} />
      <link
        rel="alternate"
        hreflang={locale}
        href={`${process.env.NEXT_PUBLIC_WEBSITE_URL}${
          locale === 'en' ? '/en' : ''
        }${asPath}`}
      />
      {post && post.translations?.length > 0 && (
        <link
          rel="alternate"
          hrefLang={locale === 'en' ? 'de' : 'en'}
          href={`${process.env.NEXT_PUBLIC_WEBSITE_URL}${
            locale === 'en' ? '/' : '/en/'
          }${
            post.__typename === 'VariableProduct'
              ? 'course/'
              : post.__typename === 'Course'
              ? 'programm/'
              : post.__typename === 'Post'
              ? 'news/'
              : ''
          }${
            post.translations[0].slug === 'home-en' ||
            post.translations[0].slug === 'home'
              ? ''
              : post.translations[0].slug + '/'
          }`}
        />
      )}

      <title>{seo?.title}</title>
      {seoFullHead ? parse(seoFullHead) : null}
      <meta name="theme-color" content="#ffffff"></meta>
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />
      <link
        rel="mask-icon"
        href="/favicon/safari-pinned-tab.svg"
        color="#5bbad5"
      />
      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link rel="shortcut icon" href="/favicon/favicon.ico" />
    </Head>
  )
}

Meta.propTypes = {
  seo: PropTypes.object.isRequired
}
