import Icon from '@/components/atoms/Icon'
import cn from 'classnames'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SearchBox} from 'react-instantsearch-dom'
import styles from './Search.module.scss'
type SearchProps = {
  searchValue: string
  setSearchValue: (value: string) => void
  isAlgolioSearch?: boolean
}

export default function Search({
  searchValue,
  setSearchValue,
  isAlgolioSearch
}: SearchProps) {
  const [focus, setFocus] = useState(false)
  const {t} = useTranslation()
  return (
    <div className={cn(styles.inputWrapper, focus ? styles.isFocus : '')}>
      {isAlgolioSearch ? (
        <SearchBox
          onChange={(e: any) => setSearchValue(e.target.value)}
          submit={false}
          translations={{
            placeholder: t('global:search')
          }}
        />
      ) : (
        <input
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          type="text"
          placeholder={t('global:search')}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      )}

      <div className={styles.iconWrapper}>
        {!isAlgolioSearch && searchValue.length > 0 && (
          <Icon
            className={styles.delete}
            icon="delete"
            size={24}
            sizeViewbox={24}
            onClick={() => setSearchValue('')}
          />
        )}
        <Icon
          className={styles.search}
          icon="search"
          size={24}
          sizeViewbox={24}
        />
      </div>
    </div>
  )
}
